import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login, SignUp, Credentials, Settings } from "../pages"
import { ProtectedRoute } from "../components/CustomHooks/ProtectedRoute";

export default function RouterPage() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/credentials"
          element={
            <ProtectedRoute>
              <Credentials />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
      </Routes>
    </Router>
  )
}