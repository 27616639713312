import { useState, useCallback } from 'react';
import {
    Filters,
    Button,
    ResourceList,
    Avatar,
    ResourceItem,
    Text,
} from '@shopify/polaris';

export default function ProductList({ items, setSynced, syncedProds, isListing = false }) {

    const [queryValue, setQueryValue] = useState("");

    const handleQueryValueChange = useCallback(
        (value) => setQueryValue(value),
        [],
    );

    const handleQueryValueRemove = useCallback(() => {
        setQueryValue("")
    }, []);

    const handleClearAll = useCallback(() => {
        handleQueryValueRemove();
    }, [handleQueryValueRemove]);

    const resourceName = {
        singular: 'product',
        plural: 'products',
    };

    const filterControl = (
        <div style={{display:"none"}}>
            <Filters
                queryValue={queryValue}
                filters={[]}
                appliedFilters={[]}
                onQueryChange={handleQueryValueChange}
                onQueryClear={handleQueryValueRemove}
                onClearAll={handleClearAll}
            >
                <div style={{ paddingLeft: '8px' }}>
                    <Button onClick={() => console.log('New filter saved')}>Search</Button>
                </div>
            </Filters>
        </div>
    );

    const renderItem = (item) => {
        const { id, image, title } = item;
        return (
            <ResourceItem
                id={id}
                media={
                    <Avatar
                        customer
                        size="medium"
                        name={title}
                        source={image ? image?.src : ""}
                    />
                }
                style={{ alignItems: "center" }}
            >
                <Text variant="bodyMd" fontWeight="bold" as="p" alignment="justify">
                    {title?.toUpperCase()}
                </Text>
            </ResourceItem>
        );
    }
    return (
        <>
            {
                isListing
                    ?
                    <ResourceList
                        resourceName={resourceName}
                        items={items}
                        renderItem={renderItem}
                    />
                    :
                    <ResourceList
                        resourceName={resourceName}
                        items={items}
                        renderItem={renderItem}
                        selectedItems={syncedProds}
                        onSelectionChange={setSynced}
                        promotedBulkActions={[{}]}
                        filterControl={filterControl}
                    />
            }
        </>
    );
}