import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {
    Page,
    Layout,
    Card,
    Button,
    ButtonGroup,
    FormLayout,
    TextField,
    Text,
    InlineError,
    Banner
} from '@shopify/polaris';

import Axios from '../../config/restConfig';
import useToken from '../CustomHooks/useToken';

function Login() {
    const navigate = useNavigate();
    const { setToken } = useToken("");

    const [domainOrEmail, setDomainOrEmail] = useState("");
    const [password, setPassword] = useState("");
    const [active, setActive] = useState(false);
    const [toast, setToast] = useState({});

    const [errors, setErros] = useState({
        email: false,
        password: false,
    });

    const handleDomainEmailChange = useCallback(
        (value) => {
            setDomainOrEmail(value)
            setErros(
                (errors) => {
                    return { ...errors, ...{ email: false } }
                }
            )
        },
        [domainOrEmail],
    );

    const handlePasswordChange = useCallback(
        (value) => {
            setPassword(value)
            setErros(
                (errors) => {
                    return { ...errors, ...{ password: false } }
                }
            )
        },
        [password],
    );

    const loginUser = async () => {

        let error = false;
        if ("" == domainOrEmail || null == domainOrEmail) {
            setErros(
                (errors) => {
                    return { ...errors, ...{ email: true } }
                }
            )
            error = true;
        }

        if ("" == password || null == password) {
            setErros(
                (errors) => {
                    return { ...errors, ...{ password: true } }
                }
            )
            error = true;
        }

        if (error) {
            return false;
        }

        await Axios.GetUnauthorizedAxios()
            .post("/login", {
                domainOrEmail: domainOrEmail,
                password: password
            })
            .then(function (response) {

                let { data } = response?.data
                setToken(data);

                navigate("/credentials")
            })
            .catch(function (error) {
                ShowToastNotif(error?.response?.data.message, "critical")
                console.log(error?.response?.data.message);
            });
    }

    const ShowToastNotif = (msg, status) => {
        setToast({ msg, status });
        setActive(true);
    };

    useEffect(() => {
        setToken("");
    }, [])


    return (
        <Page fullWidth >

            <Layout>
                <Layout.Section>
                    <Text variant="heading3xl" as="h2">
                        Login
                    </Text>
                </Layout.Section>
                <Layout.AnnotatedSection
                    id="storeDetails"
                    title="Store details"
                    description="Shopify and your customers will use this information to contact you."
                >
                    <Card sectioned>
                        {active ? (
                            <>
                                <Banner title={toast.msg} status={toast.status} onDismiss={() => setActive(false)}></Banner>
                                <br />
                            </>
                        ) : (
                            ""
                        )}
                        <FormLayout>
                            <TextField
                                label="Domain name Or Email"
                                value={domainOrEmail}
                                onChange={handleDomainEmailChange}
                                autoComplete="off"
                            />
                            {errors.email && <InlineError message="Domain Name/Email field is required" fieldID="domainFieldError" />}
                            <TextField
                                type="password"
                                label="Password"
                                value={password}
                                onChange={handlePasswordChange}
                                autoComplete="off"
                            />
                            {errors.password && <InlineError message="Password field is required" fieldID="passwordFieldError" />}
                            <ButtonGroup>
                                <Button primary onClick={loginUser}>Login</Button>
                                <Button outline onClick={() => navigate("/signup")}>Sign Up</Button>
                            </ButtonGroup>
                        </FormLayout>
                    </Card>
                </Layout.AnnotatedSection>
            </Layout>
        </Page>
    )
}

export default Login