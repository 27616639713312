import React from 'react'
import EmptyStatePage from "../../components/EmptyStatePage"

const Index = () => {
  return (
    <>
      <EmptyStatePage />
    </>
  )
}

export default Index;