import './App.css';
import RouterPage from './router';
import { AppProvider } from '@shopify/polaris';
import en from "@shopify/polaris/locales/en.json";
import "@shopify/polaris/build/esm/styles.css";

function App() {
  return (
    <div className="App">
      <AppProvider i18n={en} >
        <RouterPage />
      </AppProvider>
    </div>
  );
}

export default App;
