import React, { useCallback, useState } from 'react'
import {
    Page,
    Layout,
    Card,
    Button,
    ButtonGroup,
    FormLayout,
    TextField,
    Text,
    Banner,
    InlineError
} from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import Axios from '../../config/restConfig';

function SignUp() {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [domain, setDomain] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [active, setActive] = useState(false);
    const [toast, setToast] = useState({});
    const [errors, setErros] = useState({
        firstName: false,
        lastName: false,
        domain: false,
        wrongDomain: false,
        email: false,
        wrongEmail: false,
        password: false,
    });

    const handleFirstNameChange = useCallback(
        (value) => {
            setFirstName(value)
            setErros(
                (errors) => {
                    return { ...errors, ...{ firstName: false } }
                }
            )

        },
        [],
    );

    const handleLastNameChange = useCallback(
        (value) => {
            setLastName(value)
            setErros(
                (errors) => {
                    return { ...errors, ...{ lastName: false } }
                }
            )
        },
        [],
    );

    const handleDomainChange = useCallback(
        (value) => {
            setDomain(value)
            setErros(
                (errors) => {
                    return { ...errors, ...{ domain: false, wrongDomain: false } }
                }
            )
        },
        [],
    );

    const handleEmailChange = useCallback(
        (value) => {
            setEmail(value)
            setErros(
                (errors) => {
                    return { ...errors, ...{ email: false, wrongEmail: false } }
                }
            )
        },
        [],
    );

    const handlePasswordChange = useCallback(
        (value) => {
            setPassword(value)
            setErros(
                (errors) => {
                    return { ...errors, ...{ password: false } }
                }
            )
        },
        [],
    );

    const signUpUser = async () => {

        let error = false;
        if ("" == firstName || null == firstName) {
            setErros(
                (errors) => {
                    return { ...errors, ...{ firstName: true } }
                }
            )
            error = true;
        }

        if ("" == lastName || null == lastName) {
            setErros(
                (errors) => {
                    return { ...errors, ...{ lastName: true } }
                }
            )
            error = true;
        }

        if ("" == domain || null == domain) {
            setErros(
                (errors) => {
                    return { ...errors, ...{ domain: true } }
                }
            )
            error = true;
        }
        else if (! /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i.test(domain)) {
            setErros(
                (errors) => {
                    return { ...errors, ...{ wrongDomain: true } }
                }
            )
            error = true;
        }

        if ("" == email || null == email) {
            setErros(
                (errors) => {
                    return { ...errors, ...{ email: true } }
                }
            )
            error = true;
        }
        else if (! /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            setErros(
                (errors) => {
                    return { ...errors, ...{ wrongEmail: true } }
                }
            )
            error = true;
        }

        if ("" == password || null == password) {
            setErros(
                (errors) => {
                    return { ...errors, ...{ password: true } }
                }
            )
            error = true;
        }

        if (error) {
            return false;
        }

        await Axios.GetUnauthorizedAxios()
            .post("/signup", {
                firstName: firstName,
                lastName: lastName,
                domain: domain,
                email: email,
                password: password
            })
            .then(function (response) {
                ShowToastNotif(response?.data.message, "success")
                navigate("/login")
            })
            .catch(function (error) {
                console.log(error);
                ShowToastNotif(error?.response?.data.message, "critical")
            });
    }
    const ShowToastNotif = (msg, status) => {
        setToast({ msg, status });
        setActive(true);
    };

    return (
        <Page fullWidth >
            <Layout>
                <Layout.Section>
                    <Text variant="heading3xl" as="h2">
                        Sign Up
                    </Text>
                </Layout.Section>
                <Layout.AnnotatedSection
                    id="storeDetails"
                    title="Store details"
                    description="Shopify and your customers will use this information to contact you."
                >
                    <Card sectioned>
                        {active ? (
                            <>
                                <Banner title={toast.msg} status={toast.status} onDismiss={() => setActive(false)}></Banner>
                                <br />
                            </>
                        ) : (
                            ""
                        )}
                        <FormLayout>
                            <TextField
                                label="First Name"
                                value={firstName}
                                onChange={handleFirstNameChange}
                                autoComplete="off"
                            />
                            {errors.firstName && <InlineError message="First Name field is required" fieldID="firstNameFieldError" />}
                            <TextField
                                label="Last Name"
                                value={lastName}
                                onChange={handleLastNameChange}
                                autoComplete="off"
                            />
                            {errors.lastName && <InlineError message="Last Name field is required" fieldID="lastNameFieldError" />}
                            <TextField
                                label="Domain name"
                                value={domain}
                                onChange={handleDomainChange}
                                autoComplete="off"
                            />
                            {errors.domain && <InlineError message="Domain field is required" fieldID="domainFieldError" />}
                            {errors.wrongDomain && <InlineError message="Enter correct domain name" fieldID="wrongDomainFieldError" />}
                            <TextField
                                type="email"
                                value={email}
                                label="Account email"
                                onChange={handleEmailChange}
                                autoComplete="off"
                            />
                            {errors.email && <InlineError message="Email field is required" fieldID="emailFieldError" />}
                            {errors.wrongEmail && <InlineError message="Enter correct email" fieldID="wrongEmailFieldError" />}
                            <TextField
                                label="Password"
                                type="password"
                                value={password}
                                onChange={handlePasswordChange}
                                autoComplete="off"
                            />
                            {errors.password && <InlineError message="Password field is required" fieldID="passwordFieldError" />}
                            <ButtonGroup>
                                <Button primary onClick={signUpUser}>Sign Up</Button>
                                <Button outline onClick={() => navigate("/login")}>Back</Button>
                            </ButtonGroup>
                        </FormLayout>
                    </Card>
                </Layout.AnnotatedSection>
            </Layout>
        </Page>
    )
}

export default SignUp