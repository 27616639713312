import { useState } from 'react';

export default function useToken() {

    const getToken = () => {
        const tokenString = localStorage.getItem("weanish-auth");
        const userToken = JSON.parse(tokenString);
        return userToken
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem("weanish-auth", JSON.stringify(userToken));
        setToken(userToken);
    };

    const saveDetails = userDetails => {
        const tokenString = localStorage.getItem("weanish-auth");
        const userToken = JSON.parse(tokenString);
        if (userToken?.token) {

            let details = {
                token: userToken?.token,
                details: userDetails
            }

            localStorage.setItem("weanish-auth", JSON.stringify(details));
            setToken(details);
        }
    };

    return {
        setToken: saveToken,
        setDetails: saveDetails,
        token
    }
}