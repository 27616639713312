import React from 'react'
import SignUp from '../../components/SignUp/SignUp'

function Index() {

    return (
        <SignUp />
    )
    
}
export default Index