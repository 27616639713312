import React, { useCallback, useState, useEffect } from 'react'
import { Page, Layout, Card, Button, ButtonGroup, FormLayout, TextField, Text, InlineError } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';

import Axios from '../../config/restConfig';
import useToken from '../../components/CustomHooks/useToken';

function Index() {

    const navigate = useNavigate();
    const { token, setDetails } = useToken();

    const [tokenLoader, setTokenLoader] = useState(false);
    const [apiToken, setApiToken] = useState("");
    const [errors, setErros] = useState({
        apiToken: false,
    });

    const handleApiTokenChange = useCallback(
        (value) => {
            setApiToken(value)
            setErros(
                (errors) => {
                    return { ...errors, ...{ apiToken: false } }
                }
            )
        },
        [apiToken],
    );

    const saveToken = async () => {

        if ("" == apiToken || null == apiToken) {
            setErros(
                (errors) => {
                    return { ...errors, ...{ apiToken: true } }
                }
            )
            return false;
        }

        await Axios.GetUnauthorizedAxios()
            .post("/saveCredentials", {
                apiToken: apiToken,
                domain: token?.details?.domain
            })
            .then(function (response) {

                let { data } = response?.data
                setDetails(data?.details)

                navigate("/")
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const FetchToken = async () => {
        setTokenLoader(true);
        await Axios.GetAxios()
            .get(`/getToken?domain=${token?.details?.domain}`, {
                headers: { "content-type": "application/json" },
            })
            .then((result) => {
                setApiToken(result.data.token);
                setTokenLoader(false);
            });
    };

    useEffect(() => {
        FetchToken();
    }, []);

    return (
        <Page fullWidth >
            <Layout>
                <Layout.Section>
                    <Text variant="heading3xl" as="h2">
                        App Credentials
                    </Text>
                </Layout.Section>
                <Layout.AnnotatedSection
                    id="storeDetails"
                    title="Store details"
                    description="Shopify and your customers will use this information to contact you."
                >
                    <Card sectioned>
                        <FormLayout>
                            <TextField
                                label="Admin API Access Token"
                                onChange={handleApiTokenChange}
                                autoComplete="off"
                                value={apiToken}
                            />
                            {errors.apiToken && <InlineError message="Api Token field is required" fieldID="apiTokenError" />}
                            <ButtonGroup>
                                <Button primary onClick={saveToken}>Save</Button>
                                <Button outline onClick={() => navigate("/")}>Back</Button>
                            </ButtonGroup>
                        </FormLayout>
                    </Card>
                </Layout.AnnotatedSection>
            </Layout>
        </Page>
    )
}
export default Index