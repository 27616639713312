import React, { useCallback, useState } from 'react'
import { TopBar } from '@shopify/polaris'
import { ExitMajor, SettingsMinor } from '@shopify/polaris-icons';
import useToken from '../CustomHooks/useToken';
import { useNavigate } from 'react-router-dom';

export default function Navbar() {

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  
  const {token} = useToken();
  const navigate  = useNavigate();

  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
    [],
  );

  const userMenuMarkup = (
    token?.details ? <TopBar.UserMenu
      actions={[
        {
          items: [{ content: 'App Credentials', icon: SettingsMinor, onAction: () => navigate("/credentials") }],
        },
        {
          items: [{ content: 'Logout', icon: ExitMajor, onAction: () => navigate("/login") }],
        },
      ]}
      name={token?.details?.firstName + " " + token?.details?.lastName }
      detail={token?.details?.email}
      initials={token?.details?.firstName.substring(0, 1) + token?.details?.lastName.substring(0, 1)}
      open={isUserMenuOpen}
      onToggle={toggleIsUserMenuOpen}
    /> : <></>
  );

  return (
    <TopBar showNavigationToggle userMenu={userMenuMarkup} />
  )
}
