// import { getSessionToken } from "@shopify/app-bridge-utils";
import axios from "axios";

export default class Axios {

    static instance;
    static instance2;

    constructor(){
        
        Axios.instance = axios.create({
            baseURL: process.env.REACT_APP_SERVER_URL
        });
        Axios.instance2 = axios.create({
            baseURL: process.env.REACT_APP_SERVER_URL,
            headers: {
                Accept: 'application/json',
            },
        });
        Axios.instance.interceptors.request.use(
            function (config) {
                const tokenString = localStorage.getItem("weanish-auth");
                const userAuth = JSON.parse(tokenString);
                let token = userAuth?.token
                config.headers['Authorization'] = `Bearer ${token}`;
                // config.headers['Content-Type'] = `application/json`;
                return config;
            }
        );
    }

    static GetAxios() {
        return this.instance
    }

    static GetUnauthorizedAxios() {
        return this.instance2
    }
}
new Axios()