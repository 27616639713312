import React, { useState, useEffect } from "react";
import {
  Page,
  Layout,
  EmptyState,
  Card,
  Button,
  Spinner,
  Text,
  Modal,
  Banner,
} from "@shopify/polaris";
import Axios from "../config/restConfig";

import useToken from './CustomHooks/useToken';
import ProductList from "./ProductList/ProductList";

const BATCH_SIZE = 1;

export default function EmptyStatePage() {
  const { token } = useToken();
  const { details } = token;

  const [tokenLoader, setTokenLoader] = useState(false);
  const [syncLoader, setSyncLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [shopifyToken, setShopifyToken] = useState("");

  const [toast, setToast] = useState({});
  const [multiToast, setMultiToast] = useState([]);
  const [notificationQueue, setNotificationQueue] = useState([]);

  const [open, setOpen] = useState(false);
  const [syncedProds, setSynced] = useState([]);
  const [AllShopProducts, setAllShopProducts] = useState([]);
  const [selectedProductsDetails, setSelectedProductsDetails] = useState([]);
  const [formattedProducts, setFormattedProducts] = useState([]);

  const handleChange = () => setOpen(!open);

  const setProductsList = (isLocalState = false) => {
    let selectedProductsDetails = syncedProds.length
      ? AllShopProducts.filter((product) =>
        syncedProds.includes(product.id)
      )
      : [];
    setSelectedProductsDetails(selectedProductsDetails);
    setOpen(false);
    if (!isLocalState) {
      SetSelectedProducts(
        selectedProductsDetails.map((x) => {
          return {
            id: x.id,
            handle: x.handle,
            title: x.title,
            image: x.image,
            skuList: x.variants.map((y) => {
              return {
                id: y.id,
                price: y.price,
                sku: y.sku,
                title: y.title,
              };
            }),
          };
        })
      );
    }
  };

  const ShowToastNotif = (msg, status, delay = 5000) => {
    const newNotification = { msg, status };
    setNotificationQueue((prevQueue) => [...prevQueue, newNotification]);

    setTimeout(() => {
      setNotificationQueue((prevQueue) => prevQueue.slice(1)); // Remove the first notification
    }, delay);
  };

  const SetSelectedProducts = async (products) => {
    setLoader(true);
    setFormattedProducts(products);
    await Axios.GetAxios()
      .post(
        "/setSelectedProducts",
        {
          products: products,
          domain: details?.domain,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then((response) => {
        setLoader(false);
        setSynced(products.map((product) => product.id));

        if (products.length) {
          ShowToastNotif("Products Selected for Sync", "success");
        } else {
          // setActive(false); // Remove this line
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error?.response?.data);
        ShowToastNotif(
          "Something went wrong while selecting the products",
          "critical"
        );
      });
  };

  useEffect(() => {
    getAllShopProducts();
    FetchToken();
    FetchSelected();
  }, []);

  const FetchSelected = async () => {
    setLoader(true);
    await Axios.GetAxios()
      .get(`/getSelectedProducts?domain=${details.domain}`, {
        headers: { "content-type": "application/json" },
      })
      .then((request) => {
        let selectedProducts = JSON.parse(
          JSON.stringify(request?.data?.products[0]?.SKUList)
        );
        setLoader(false);
        setSynced(selectedProducts.map((product) => product.id));
        setSelectedProductsDetails(selectedProducts);
      });
  };

  const FetchToken = async () => {
    setTokenLoader(true);
    await Axios.GetAxios()
      .get(`/getToken?domain=${details.domain}`, {
        headers: { "content-type": "application/json" },
      })
      .then((result) => {
        setShopifyToken(result.data.token);
        setTokenLoader(false);
      });
  };

  const getAllShopProducts = async () => {
    await Axios.GetAxios()
      .get(`/getAllShopProducts?domain=${details.domain}`, {
        headers: { "content-type": "application/json" },
      })
      .then((response) => {
        // Filter out products with an empty SKU
        const filteredProducts = response?.data?.result.filter(
          (product) => product.variants.some((variant) => variant.sku !== "")
        );

        setAllShopProducts(filteredProducts);
        setTokenLoader(false);
      });
  };

  const syncProductsAndShowNotifications = async (
    batchProducts,
    SKU,
    includeYesKey
  ) => {
    try {
      const serializedProducts = batchProducts
        .map((product) => encodeURIComponent(JSON.stringify(product)))
        .join(",");

      let url = `/manualSync?domain=${details.domain}&sku=${SKU}`;

      if (includeYesKey) {
        url += "&firstIteration=yes";
      }

      const response = await Axios.GetAxios().get(url, {
        headers: { "content-type": "application/json" },
      });

      if (response?.data?.status === "success") {
        batchProducts.forEach((product) => {
          ShowToastNotif(
            `${response?.data?.data.message}`,
            "success"
          );
        });
      } else {
        batchProducts.forEach((product) => {
          ShowToastNotif(
            `${response?.data?.data.message}`,
            "critical"
          );
        });
      }
    } catch (error) {
      batchProducts.forEach((product) => {
        ShowToastNotif(
          `Product "${product.title}" Sync Failed: ${error.message}`,
          "critical"
        );
      });
    }
  };

  const SyncNow = async () => {
    setSyncLoader(true);

    try {
      const batchSize = Math.ceil(formattedProducts.length / BATCH_SIZE);
      const SKUS = formattedProducts.map(
        ({ skuList }) => skuList?.[0]?.sku
      );

      for (let i = 0; i < batchSize; i++) {
        const batchProducts = formattedProducts.slice(
          i * BATCH_SIZE,
          (i + 1) * BATCH_SIZE
        );
        const includeYesKey = i === 0;
        await syncProductsAndShowNotifications(
          batchProducts,
          SKUS[i],
          includeYesKey
        );
      }
      setSyncLoader(false);
    } catch (error) {
      setSyncLoader(false);
      ShowToastNotif("Products Sync Initiation Failed", "critical");
    }
  };

  const OpenResourcePicker = () => {
    setOpen(true);
  };

  const dismissBanner = (bannerId) => {
    setMultiToast(multiToast.filter((banner, index) => index !== bannerId));
  };
  const displayNotifications = () => {
    return notificationQueue.map((notification, index) => (
      <Banner
        key={index}
        title={notification.msg}
        status={notification.status === "success" ? "success" : "critical"}
        onDismiss={() => dismissBanner(index)}
      />
    ));
  };

  return (
    <>
      <Page>
        <div>
          {multiToast.length
            ? multiToast?.map((element, index) => {
              return (
                <>
                  <Banner
                    title={element?.message}
                    status={
                      element?.status == "success" ? "success" : "critical"
                    }
                    onDismiss={() => dismissBanner(index)}
                  ></Banner>
                  <br />
                </>
              );
            })
            : <></>}

          {displayNotifications()} {/* Display the new notifications */}

          <Layout>
            <Layout.Section>
              {loader ? (
                <Spinner
                  accessibilityLabel="Spinner example"
                  size="large"
                />
              ) : (
                <Card>
                  <Card.Section subdued>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        variant="headingLg"
                        as="h5"
                        alignment="justify"
                      ></Text>
                      <Button
                        loading={syncLoader}
                        primary
                        onClick={SyncNow}
                        disabled={!syncedProds.length}
                      >
                        Sync Products
                      </Button>
                    </div>
                  </Card.Section>
                  <Card.Section>
                    {selectedProductsDetails.length ? (
                      <>
                        <ProductList
                          items={selectedProductsDetails}
                          syncedProds={syncedProds}
                          isListing={true}
                        />
                        <hr />
                      </>
                    ) : (
                      <></>
                    )}
                    <EmptyState
                      heading={!loader && syncedProds.length ? "" : "Select Now"}
                      action={{
                        content:
                          !loader && syncedProds.length
                            ? "Add/Remove Products"
                            : "Select Now",
                        onAction: () => OpenResourcePicker(),
                      }}
                    >
                      <p>
                        Select Products to Sync to Weanish marketplace.
                      </p>
                    </EmptyState>
                  </Card.Section>
                </Card>
              )}
            </Layout.Section>
          </Layout>
        </div>

        <Modal
          title="Product Selection"
          open={open}
          onClose={() => setOpen(false)}
          primaryAction={{
            content: 'Add / Remove',
            onAction: () => setProductsList(false),
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: handleChange,
            },
          ]}
        >
          <Modal.Section>
            <ProductList
              items={AllShopProducts}
              setSynced={setSynced}
              syncedProds={syncedProds}
            />
          </Modal.Section>
        </Modal>
      </Page>
    </>
  );
}