import { Navigate } from "react-router-dom";
import useToken from "./useToken";
import { Frame } from '@shopify/polaris';
import Navbar from "../NavBar/Navbar";

export const ProtectedRoute = ({ children }) => {
  
  const logo = {
    width: 100,
    topBarSource: require('../../Weanish-logo-FC-spot.png'),
    url: '/',
    accessibilityLabel: 'Weanish',
  };

  const { token } = useToken();

  if (!token) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return <Frame topBar={<Navbar />} logo={logo}>{children}</Frame>;
};